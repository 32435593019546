<template>
  <section id="SERVICES" class="bg-white md:mb-[150px] mb-[20px]">
    <div class="">
      <img class="mx-auto md:block hidden mt-[80px] w-[40%]" src="../assets/services/servicesSection.png"/>
      <img class="mx-auto md:hidden py-[5vh] w-[285px]" src="../assets/services/h5services.png"/>
      <div class="md:text-2xl text-md md:text-start text-center w-[80%] mx-auto text-[#999999]">
        <!--        fontHelvetica-->
        SOLIDLEISURE SOLUTIONS INC. is focused on developing innovative operating systems and delivering O2O solutions
        that offer a seamless integration of the virtual and real worlds, ensuring an exceptional user experience.
        From the user's perspective, we begin by gathering prerequisites in collaboration with frontline product managers.
        UI designers then create the interface for apps, websites, or other interactive media.
        These designs are handed over to engineers for development, followed by testing by quality assurance engineers
        who perform functional, performance, security, and other debugging tasks. Through continuous verification and
        refinement, we ensure the best possible experience for our customers across various domains.
      </div>
      <div class="md:text-2xl text-md md:text-start text-center w-[80%] mx-auto text-[#999999] my-5">
        Our team is proficient in a wide range of languages and frameworks, including front-end technologies like
        Vue.js, HTML5, CSS, Webpack, and Electron, as well as back-end technologies such as
        Node.js, Java (SSH), C++, GO, and mobile app development for Android (Java/Kotlin) and iOS (Swift).
        We also specialize in architecture design and algorithm development.
        By hiring and fostering top-tier talent, we create high-quality software products that meet the highest standards.
      </div>
    </div>
  </section>
</template>
<script>
// import SLLogo from "../assets/SLLogo.png"
export default {
  name: "ServicesPage",
  // data: function () {
  //   return {
  //     SLLogo: SLLogo
  //   }
  // }
};
</script>
<style scoped>
.fontHelvetica {
  font-family: HelveticaNeueLt !important;
  letter-spacing: 0.01em !important;
  line-height: 30px;
}

#SERVICES {
  scroll-margin-top: 10em;
}

.animate {
  transition-delay: .4s;
  transition-duration: 2s;
  transition-timing-function: ease
}

.slide-right {
  /*transform: translateY(-100px)*/
  opacity: 0;
  transform: translateX(-50px);
}

.slide-right.animate-active {
  /*transform: translateY(0)*/
  opacity: 1;
  transform: translateX(0);
}

.slide-left {
  /*transform: translateY(-100px)*/
  opacity: 0;
  transform: translateX(50px);
}

.slide-left.animate-active {
  /*transform: translateY(0)*/
  opacity: 1;
  transform: translateX(0);
}


</style>
